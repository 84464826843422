import axios from "axios";
// import config from "../../data/config"; // no use
// import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

async function apiHandler(uploadFormData, token, setLoading,authCtx,history) {
  // console.log('uploadFormData',uploadFormData);
  setLoading(true);
  await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL_NODE}/user/createrequest`,
      uploadFormData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      toast.success("Request created!");
      setLoading(false);

      setTimeout(() => {
        history.push('/reports')

        // let baseUrl = window.location.origin + "/reports";
        // window.location.href = baseUrl;
      }, 100);
    })
    .catch((err) => {
      setLoading(false);
      if(!authCtx.checkErrorStatus(err.response)){
        toast.error("Something went wrong. Please try again.");
      }
      setTimeout(() => {
        history.push('/reports')
        // let baseUrl = window.location.origin + "/reports";
        // window.location.href = baseUrl;
      }, 100);
    });
}

export default apiHandler;
