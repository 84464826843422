import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const OverlayComp = () => {
  const [message, setMessage] = useState(
    "Website currently works only on Desktop view"
  );
  let location = useLocation();
  useEffect(() => {
    if (location && location.data && location.state && location.state==="/pricing") {
      setMessage(location.data);
    }
  }, [location]);
  return (
    <div
      style={{
        background: "white",
        position: "fixed",
        overflow: "show",
        margin: "auto",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        zIndex: "99999999999999999999",
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: " #fff",
          height: "100vh",
          justifyContent: "center",
          display: "flex",
          left: "0",
          margin: "0",
          padding: "0",
          position: "absolute",
          top: "0",
          width: "100vw",
        }}
      >
        <p
          style={{
            fontSize: "25px",
            color: "#0f9b51",
            fontWeight: "600",
            display: "flex",
            padding: "2rem",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default OverlayComp;
