import React, { useEffect } from "react";
import greendot from "../../assets/greendot.svg";
import "./CssModules/privacy.css";
import { MdOutlinePrivacyTip, MdSecurity } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";
import AOS from "aos";
import Partners from "./Partners";

const Privacy = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div id="landingprivacy" className="plkgapbetweencontainer">
      <div className="left-dot-landingprivacy">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="landingprivacy-title-group">
        <h1 className="dtitle" data-aos="fade-up" data-aos-offset="50">
          Privacy
        </h1>
        <p className="pwsub" data-aos="fade-up" data-aos-offset="50">
          At Lending Katalyst, the privacy and security of our customers and
          their data is our top priority.
        </p>
      </div>
      <div className="right-dot-landingprivacy">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="container pt-4 pb-4 extrapbcalc">
        <div className="row justify-content-center">
          <div
            className="col-md-12 col-lg-4 col-sm-12 pt-4 pb-4 d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-offset="50"
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <div className="ppc-container">
              <div id="pp-policy-round">
                <MdOutlinePrivacyTip id="p-greencoloricon" />
              </div>
              <span className="p-head">Data Privacy</span>
              <span className="p-subhead">
                We never sell customer data or share customer contacts with
                third parties.
              </span>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-4 col-sm-12  pt-4 pb-4 d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-offset="50"
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <div className="ppc-container">
              <div id="pp-policy-round">
                <MdSecurity id="p-greencoloricon" />
              </div>
              <span className="p-head">Security</span>
              <span className="p-subhead">
                We have strict policies in place that ensure our methods are
                properly communicated and customer data is always secure.
              </span>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-4 col-sm-12  pt-4 pb-4  d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-offset="50"
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <div className="ppc-container">
              <div id="pp-policy-round">
                <GrCompliance
                  style={{
                    height: "52px",
                    width: "52px",
                  }}
                  id="p-greencoloricon"
                />
              </div>
              <span className="p-head">Compliances</span>
              <span className="p-subhead">
                We ensure that we strictly adhere to all kinds of compliances
                applicable to us.
              </span>
            </div>
          </div>
          {/* <div className="col-md-1"></div> */}
        </div>
      </div>
      <div
        className="container">
      <div className="row partners">
        <div className="col-md-4 d-none d-lg-flex line"><hr></hr></div>
        <div className="col-md-4 col-sm-12 line"><p className="pwsub" style={{fontWeight: 500}} align="center"><span style={{fontWeight: 200}}>Our</span> Customers</p></div>
        <div className="col-md-4 d-none d-lg-flex line"><hr></hr></div>
            <Partners />
      </div>
      </div>
    </div>
  );
};

export default Privacy;
