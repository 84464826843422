import React, { useEffect } from "react";
import "./CssModules/whyus.css";
import greendot from "../../assets/greendot.svg";
import { BiTimer, BiData } from "react-icons/bi";
import { FaLanguage } from "react-icons/fa";
import { TbRotate360, TbArrowsMaximize } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import AOS from "aos";
import Testimonials from "./Testimonials";

const WhyUs = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div id="whyus" className="lkgapbetweencontainerwhyus">
      <div className="left-dot-whyus">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="whyus-title-group">
        <h1 className="dtitle" data-aos="fade-up" data-aos-offset="50">
          Why us?
        </h1>
        {/* <p className="wsub">Title search made simple</p> */}
      </div>
      <div className="right-dot-whyus">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="container whyusptb whyuspadbottom whyuspadtop">
        <div className="row pt-4 pb-4 onezerothree">
          <div
            className="col-md-6 col-lg-4 col-sm-12 justify-content-center d-flex padb37 pt-4 nextpad"
            data-aos="fade-right"
            data-aos-offset="50"
          >
            <div className="whyuscontentcontainer">
              <span className="why-t">Minimum TAT</span>
              <div id="whyuicon">
                <BiTimer id="greencoloricon" />
              </div>
              <span className="why-sub">
                Get your property title report in few minutes
              </span>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 col-sm-12 justify-content-center d-flex padb37 pt-4 nextpad"
            data-aos="zoom-in"
            data-aos-offset="50"
          >
            <div className="whyuscontentcontainer">
              <span className="why-t">Vernacular Documents</span>
              <div id="whyuicon">
                <FaLanguage id="greencoloricon" />
              </div>
              <span className="why-sub">
                The platform can analyse both English and vernacular documents
              </span>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 col-sm-12 justify-content-center d-flex padb37 pt-4 nextpad"
            data-aos="fade-left"
            data-aos-offset="50"
          >
            <div className="whyuscontentcontainer">
              <span className="why-t">Data Driven</span>
              <div id="whyuicon">
                <BiData id="greencoloricon" />
              </div>
              <span className="why-sub">
                Our ML-algorithm has been trained on a large set of well-curated
                data
              </span>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 col-sm-12 justify-content-center d-flex padb37 pt-4 nextpad"
            data-aos="fade-up"
            data-aos-offset="50"
          >
            <div className="whyuscontentcontainer">
              <span className="why-t">360 Degree Check</span>
              <div id="whyuicon">
                <TbRotate360 id="greencoloricon" />
              </div>
              <span className="why-sub">
                To verify if the property has a clear and marketable title
              </span>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 col-sm-12 justify-content-center d-flex padb37 pt-4 nextpad"
            data-aos="fade-up"
            data-aos-offset="50"
          >
            <div className="whyuscontentcontainer">
              <span className="why-t">Extensive Coverage</span>
              <div id="whyuicon">
                <TbArrowsMaximize id="greencoloricon" />
              </div>
              <span className="why-sub">
                Our platform can analyse property documents from year 2004{" "}
              </span>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 col-sm-12 justify-content-center d-flex padb37 pt-4 nextpad"
            data-aos="fade-up"
            data-aos-offset="50"
          >
            <div className="whyuscontentcontainer">
              <span className="why-t">Digital Title Report</span>
              <div id="whyuicon">
                <HiOutlineDocumentReport id="greencoloricon" />
              </div>
              <span className="why-sub">
                No more physical reports, all your reports under one single
                destination
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
