import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import AuthContext from "../../store/auth-context";
import classes from "./MainNavigation.module.css";
import "./navbar.css";
import { AiOutlineClose } from "react-icons/ai";
import { CgMenu } from "react-icons/cg";

import { RiLock2Line } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { BsPersonCircle } from "react-icons/bs";
import toast from "react-hot-toast";
import { AiOutlineCaretDown } from "react-icons/ai";
import {BsPerson} from 'react-icons/bs'
import {RxDashboard} from 'react-icons/rx';
import { FaXTwitter } from "react-icons/fa6";


import Dropdown from "react-bootstrap/Dropdown";

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [checkBoxChecked, setCheckboxChecked] = useState(false);
  const [checkIfInDashboard, setCheckIfInDashboard] = useState(false);
  const [showHideCompanyMenu, setShowHideCompanyMenu] = useState(false);

  const isLoggedIn = authCtx.isLoggedIn();
  const userData = authCtx.userData();
  const location = useLocation();

  const showOrHideMiddleMenu = () => {
    if (
      !isLoggedIn &&
      (window.location.pathname === "/" ||
        window.location.pathname === "/pricing" ||
        window.location.pathname === "/aboutus" ||
        window.location.pathname === "/careers" ||
        window.location.pathname === "/faqs" ||
        window.location.pathname === "/contactus")
    ) {
      return true;
    } else {
      if (
        (isLoggedIn && window.location.pathname == "/") ||
        window.location.pathname === "/pricing" ||
        window.location.pathname === "/aboutus" ||
        window.location.pathname === "/careers" ||
        window.location.pathname === "/faqs" ||
        window.location.pathname === "/contactus"
      ) {
        return true;
      }
    }
    return false;
  };

  const checkIfEnterPriseRoute = () => {
    if (
      window.location.pathname.includes("editreports") ||
      window.location.pathname.includes("partitionreview") ||
      window.location.pathname.includes("report-status")
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const navLinks = document.querySelectorAll("nav ul li a");
    let url = window.location.href;
    let sectionId = url.split("#")[1];

    if (window.location.pathname == "/dashboard") {
      setCheckIfInDashboard(true);
    } else {
      setCheckIfInDashboard(false);
    }
    if (window.location.pathname == "/") {
      let nav = document.querySelector("nav");
      nav.style.position = "fixed";
    } else {
      let nav = document.querySelector("nav");
      nav.style.position = "fixed";
      nav.style.top = 0;
    }
    if (sectionId) {
      setTimeout(() => {
        // console.log('---sectionId',sectionId)
        navigateTo(`#${sectionId}`);
      }, 10);
    }

    let prevScrollpos = 50;
    const handleScroll = () => {
      if (window.location.pathname == "/") {
        let currentScrollPos = window.scrollY;
        let nav = document.querySelector("nav");

        if (prevScrollpos > currentScrollPos) {
          nav.style.top = "0";
          if (currentScrollPos === 0) {
            if (nav.classList.contains("boxShadow")) {
              nav.classList.remove("boxShadow");
              // nav.style.top = "0";
            }
          } else {
            if (!nav.classList.contains("boxShadow"))
              nav.classList.add("boxShadow");
          }
          // document.querySelector("nav").style.top = "0";
        } else {
          if (currentScrollPos != 0 && currentScrollPos > 50) {
            nav.style.top = "-100px";
            if (nav.classList.contains("boxShadow"))
              nav.classList.remove("boxShadow");
          }
        }
        prevScrollpos = currentScrollPos;
      }
      let current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeigh = section.clientHeight;
        if (window.scrollY >= sectionTop - sectionHeigh / 2) {
          current = section.getAttribute("id");
        }
      });

      navLinks.forEach((li) => {
        li.classList.remove("activeNavLink");

        if (li.classList.contains(current)) {
          li.classList.add("activeNavLink");
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    // return () => window.removeEventListener("scroll", handleScroll);
  }, [window.location.href]);

  const navigateTo = (id) => {
    const section = document.querySelector(id);
    if (section) {
      const sectionTop = section.offsetTop;
      // window.scrollTo({ top: sectionTop - 87 });
      window.scrollTo({ top: sectionTop });
    } else {
      history.push(`/${id}`);
    }
  };

  const mobileNavigateTo = (id) => {
    const section = document.querySelector(id);
    let smallNavSections = document.querySelector(".smallNavSections");
    if (section) {
      const sectionTop = section.offsetTop;
      if (smallNavSections) {
        setCheckboxChecked(!checkBoxChecked);
        smallNavSections.style.display = "none";
      }
      window.scrollTo({ top: sectionTop });
    } else {
      if (smallNavSections) {
        setCheckboxChecked(!checkBoxChecked);
        smallNavSections.style.display = "none";
      }
      history.push(`/${id}`);
    }
  };

  const logoutHandler = () => {
    authCtx.logout();
    toast.success("Logout successful");
    // optional: redirect the user
  };
  const checkPartionReviewPage = () => {
    const pathName=window.location.pathname;
    return (
      pathName.includes("partitionreview") ||
      pathName.includes("report-status") ||
      pathName.includes("reports/partition/review") 
    );
  };

  const checkStickyRpoutes=()=>{
    const pathName=window.location.pathname;
    return (
      pathName.includes("draft-deed") ||
      pathName.includes("reports") && 
      !pathName.includes('/reports/segregator-table/') &&
      !pathName.includes('/reports/edit') &&
      !pathName.includes('/reports/partition') &&
      !pathName.includes('/editreports') 
  );
  }

  return (
    <nav
      className={`${classes.navbar} ${
        checkPartionReviewPage() ? "boxShadow1" : ""
      } ${checkStickyRpoutes()?'_sticky_position':''}`}
      style={{maxHeight:'99px'}}
    >
      <div className={classes.logoDiv}>
        {!checkIfEnterPriseRoute() && (
          <div
            style={{
              cursor: "pointer",
            }}
            className={classes.logoDivAnchor}
          >
            <div className={classes.logoContainer}>
              <img
                alt="LK"
                src="/img/logo2.png"
                className={classes.logo}
                loading="eager"
              />

              <div className={classes.title} style={{ whiteSpace: "nowrap" }}>
                Lending <span style={{ fontWeight: 650 }}>Katalyst</span>
              </div>
            </div>
          </div>
        )}
        {checkIfEnterPriseRoute() && (
          <Link to="/" className={classes.logoDivAnchor}>
            <div className={classes.logoContainer}>
              <img
                alt="LK"
                src="/img/logo2.png"
                className={classes.logo}
                loading="eager"
              />

              <div className={classes.title} style={{ whiteSpace: "nowrap" }}>
                Lending <span style={{ fontWeight: 650 }}>Katalyst</span>
              </div>
            </div>
          </Link>
        )}
      </div>
      {showOrHideMiddleMenu() && (
        <ul className={classes.navSections}>
          <li>
            <Link className="whatwedo" to="/pricing">
              Pricing
            </Link>
          </li>

          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              className="bg-white border-0 p-0 hoverClass"
              id="dropdown-basic-company"
            >
              Company
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="bottom-border"
                onClick={() => history.push("/aboutus")}
              >
                <div
                  className="d-flex align-items-center "
                  style={{ gap: "6px" }}
                >
                  <span className="drop-menu-name">About Us</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => history.push("/contactus")}>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "6px" }}
                >
                  <a
                    href="/#contact"
                    style={{
                      color: "#212529",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                    }}
                    className="contact"
                  >
                    Contact
                  </a>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <li className="d-flex align-items-center">
            <Link to="/careers">Careers</Link>
            <span
              className={classes.werarehiringtext}
              style={{ padding: "8px" }}
            >
              We are hiring!
            </span>
          </li>
          <li>
            <Link to="/faqs">FAQs</Link>
          </li>
        </ul>
      )}

      <div className={classes.navActions}>
        {!isLoggedIn && checkIfEnterPriseRoute() && (
          <>
            {/* <div
              className={classes.button}
              onClick={() => history.push("/demo")}
            >
              Request Demo
            </div> */}
            <div
              className={classes.button}
              onClick={() => history.push("/login")}
            >
              Log In
            </div>
            <div
              className={`${classes.signUp} ${classes.button}`}
              onClick={() => history.push("/signup")}
            >
              Sign Up
            </div>
          </>
        )}
        {isLoggedIn && checkIfEnterPriseRoute() && (
          <>
            <div
              className={classes.button}
              onClick={() => logoutHandler()}
              style={{ display: "none" }}
            >
              Log Out
            </div>
            {!checkIfInDashboard && (
              <div
                className={`${classes.signUp} ${classes.button}`}
                onClick={() => history.push("/dashboard")}
              >
                Dashboard
              </div>
            )}

            <div className="profile-dropddd">
              <Dropdown >
                <Dropdown.Toggle
                  variant="light"
                  className="bg-white border-0 p-0"
                  id="dropdown-basic"
                >
                  <BsPersonCircle />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{maxWidth:'190px'}}>
                  <Dropdown.Item className="bottom-border">
                    <div
                      className="d-flex align-items-center "
                      style={{ gap: "6px" }}
                    >
                      <BsPerson
                       style={{
                          fontSize: "25px",
                        }}
                       />
                      <span className="drop-menu-name_with_user_name" 
                      style={{
                        whiteSpaces:'break-spaces'
                      }}>
                       
                        {userData && userData.full_name
                          ?"Hi" +" " +userData.full_name
                          : ""}
                      </span>
                    </div>
                  </Dropdown.Item>
                  {
                    userData.role==='admin' && (
                      <Dropdown.Item className="bottom-border admin_dashboard_item"
                      onClick={()=>history.push('/admin-dashboard')}
                      >
    
    
                        <div
                          className="d-flex align-items-center "
                          style={{ gap: "6px" }}
                          
                        >
                          <RxDashboard
                           style={{
                              fontSize: "25px",
                            }}
                           />
                          <span className="drop-menu-name_with_user_name" 
                          style={{
                            whiteSpaces:'break-spaces'
                          }}>
                           
                            Admin Dashboard
                          </span>
                        </div>
                      </Dropdown.Item>
                    )
                  }
                  <Dropdown.Item
                    className="bottom-border"
                    onClick={() => history.push("/forgot-password")}
                  >
                    <div
                      className="d-flex align-items-center "
                      style={{ gap: "6px" }}
                    >
                      <RiLock2Line
                        style={{
                          fontSize: "25px",
                        }}
                      />
                      <span className="drop-menu-name">Change Password</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => logoutHandler()}>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "6px" }}
                    >
                      <IoLogOutOutline
                        style={{
                          fontSize: "25px",
                        }}
                      />
                      <span className="drop-menu-name">Log Out</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
      </div>
      {checkIfEnterPriseRoute() && (
        <div className={classes.navMenuButton}>
          <input
            type="checkbox"
            id="checkbox4"
            onChange={(e) => setCheckboxChecked(e.target.checked)}
            className="checkbox4 visuallyHidden"
            value={checkBoxChecked}
          />
          {!checkBoxChecked ? (
            <CgMenu
              style={{ fontSize: "25px", color: "#0f9b51" }}
              onClick={() => setCheckboxChecked(true)}
            />
          ) : (
            <AiOutlineClose
              style={{ fontSize: "25px", color: "#0f9b51" }}
              onClick={() => setCheckboxChecked(false)}
            />
          )}
        </div>
      )}

      <div className={classes.uldiv}>
        <ul
          style={{ display: checkBoxChecked ? "block" : "none" }}
          className={`${classes.smallNavSections} smallNavSections`}
        >
          <li>
            <Link
              className="whatwedo"
              to="/pricing"
              onClick={() => setCheckboxChecked(!checkBoxChecked)}
            >
              Pricing
            </Link>
          </li>
          <li onClick={() => setShowHideCompanyMenu(!showHideCompanyMenu)}>
            <a href="javascript:void(0)">Company</a> <AiOutlineCaretDown />
          </li>
          {showHideCompanyMenu && (
            <div className={classes.withani}>
              <li>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    history.push("/aboutus");
                    setCheckboxChecked(!checkBoxChecked);
                    setShowHideCompanyMenu(!showHideCompanyMenu);
                  }}
                >
                  About Us
                </a>
              </li>
              <li
                style={{
                  marginBottom: "20px",
                }}
              >
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    history.push("/contactus");
                    setCheckboxChecked(!checkBoxChecked);
                    setShowHideCompanyMenu(!showHideCompanyMenu);
                  }}
                >
                  Contact
                </a>
              </li>
            </div>
          )}

          <li>
            <Link
              to="/careers"
              onClick={() => setCheckboxChecked(!checkBoxChecked)}
            >
              Careers{" "}
            </Link>
            <span className={classes.werarehiringtext}>We are hiring!</span>
          </li>
          <li
            style={{
              marginBottom: "20px",
            }}
          >
            <Link
              to="/faqs"
              onClick={() => setCheckboxChecked(!checkBoxChecked)}
            >
              FAQs
            </Link>
          </li>
          <li
            style={{
              padding: "0 10px 10px 0",
            }}
          >
            <span className={classes.connectwithus}>Connect with us</span>
            <div className="pt-4">
              <ul className="d-flex justify-content-evenly w-75 m-auto">
                <li className={classes.sociallinkssmallnavli}>
                  <a
                    href="https://www.facebook.com/LendingKatalyst"
                    target="_blank"
                    id={classes.sociallinkssmallnavlia}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li className={classes.sociallinkssmallnavli}>
                  <a
                    href="https://www.linkedin.com/company/lending-katalyst"
                    target="_blank"
                    id={classes.sociallinkssmallnavlia}
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li className={classes.sociallinkssmallnavli}>
                  <a
                    href="https://twitter.com/LendingKatalyst"
                    target="_blank"
                    id={classes.sociallinkssmallnavlia}
                  >
                    <FaXTwitter className={classes.twitter_icon} />

                    {/* <i className="fa fa-twitter"></i> */}
                  </a>
                </li>
                <li className={classes.sociallinkssmallnavli}>
                  <a
                    href="https://www.instagram.com/lendingkatalyst/"
                    id={classes.sociallinkssmallnavlia}
                    target="_blank"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MainNavigation;
