import React, { useEffect } from "react";
import "./CssModules/CustomersSay.css";
import greendot from "../../assets/greendot.svg";
import { BiTimer, BiData } from "react-icons/bi";
import { FaLanguage } from "react-icons/fa";
import { TbRotate360, TbArrowsMaximize } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import AOS from "aos";
import Testimonials from "./Testimonials";

const CustomersSay = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div id="whyus" className="lkgapbetweencontainerwhyus">
      <div className="left-dot-whyus">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="whyus-title-group">
        <h1 className="dtitle" data-aos="fade-up" data-aos-offset="50" style={{paddingBottom: '15px'}}>
          What our
        </h1>
        <h1 className="dtitle" data-aos="fade-up" data-aos-offset="50">
          customers say
        </h1>
        {/* <p className="wsub">Title search made simple</p> */}
      </div>
      <div className="right-dot-whyus">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="container whyusptb whyuspadbottom whyuspadtop">
        <Testimonials />
      </div>
    </div>
  );
};

export default CustomersSay;
