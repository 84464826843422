import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './CssModules/testimonial.css';
import Denovo from '../../assets/denovo-testimonial.jpg'
import Settlin from '../../assets/settlin-testimonial.jpg'
import SP from '../../assets/sp-testimonial.jpg'
import { isMobile } from 'react-device-detect';

const Testimonials = () => {
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 1,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 1,
            partialVisibilityGutter: 30
        }
    };

    return <Carousel
        additionalTransfrom={0}
        arrows={isMobile ? false: true}
        autoPlay={false}
        autoPlaySpeed={1000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        // customTransition="all 5s linear"
        dotListClass=""
        draggable={false}
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay={false}
        showDots={isMobile ? true : false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        deviceType=""
    // transitionDuration={5000}
    >
        <div className="testimonial">
            <div className="testimonial-details">
                <div className="photo-wrapper">
                    <img src={Denovo} height={272} width={272} className="testimonial-image" />
                </div>
                <div class="testimonial-text-wrapper">
                    <div class="testimonial-text">Lending Katalyst's innovative solution has fundamentally transformed our operations, allowing us to deliver faster, more accurate results to our clients. We're incredibly grateful for the impact it's had on our practice, saving us time and effort while enhancing the quality of our services.</div>
                    <div class="testimonial-name-and-position-wrapper">
                        <div class="p-n_wrapper">
                            <div class="position-wrapper">
                                <div>Sanjay Sugumaran</div>
                                <div class="tt-divider">|</div>
                                <div>Partner</div>
                                <div>,</div>
                            </div>
                            <div>De Novo Law Firm</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="testimonial">
            <div className="testimonial-details">
                <div className="photo-wrapper">
                    <img src={Settlin} height={272} width={272} className="testimonial-image" />
                </div>
                <div class="testimonial-text-wrapper">
                    <div class="testimonial-text">Lending Katalyst is the only one doing real problem solving in this space. They are not shying away from deep complexities and addressing them to the core. Super team and super product.</div>
                    <div class="testimonial-name-and-position-wrapper">
                        <div class="p-n_wrapper">
                            <div class="position-wrapper">
                                <div>Ashish Srivastava</div>
                                <div class="tt-divider">|</div>
                                <div>CEO</div>
                                <div>,</div>
                            </div>
                            <div>Settlin</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="testimonial">
            <div className="testimonial-details">
                <div className="photo-wrapper">
                    <img src={SP} height={272} width={272} className="testimonial-image" />
                </div>
                <div class="testimonial-text-wrapper">
                    <div class="testimonial-text">Lending Katalyst, with its unique and innovative way of enhancing the quality of service we provide to our clients, has considerably saved us time and effort. We recommend the platform.</div>
                    <div class="testimonial-name-and-position-wrapper">
                        <div class="p-n_wrapper">
                            <div class="position-wrapper">
                                <div>Pavan Kumar T</div>
                                <div class="tt-divider">|</div>
                                <div>CEO</div>
                                <div>,</div>
                            </div>
                            <div>SecureProp</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel>;
}

export default Testimonials;

