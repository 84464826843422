import React, { useState } from "react";

const Footer = ({ otherStyle, css }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  let footerstyle = {
    width: "100%",
    minHeight: "60px",
    backgroundColor: "lightgray",
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div style={{ ...footerstyle, ...otherStyle }}>
      <p style={{ fontSize: "18px",margin:'0' }} className={css}>
        &copy; {year} Lending Katalyst. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
