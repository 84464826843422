import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Image from 'react-bootstrap/Image';
import Vakil from '../../assets/libra.png';
import Denovo from '../../assets/denovo.jpg'
import Settlin from '../../assets/settlin.PNG';
import SP from '../../assets/sp-logo.png'
import './CssModules/partners.css';
import { isMobile } from 'react-device-detect';

const Partners = () => {
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 4,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
        }
    };

    return <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay={isMobile ? true : false}
        autoPlaySpeed={1000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        // customTransition="all 5s linear"
        dotListClass=""
        draggable={false}
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay={false}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        deviceType=""
        // transitionDuration={5000}
    >
        <div className="image-container"><img src={Settlin} className="image" /></div>
        <div className="image-container"><img src={Vakil} className="image" /></div>
        <div className="image-container"><img src={Denovo} className="image" /></div>
        <div className="image-container sp-image"><img src={SP} className="image" /></div>
    </Carousel>;
}

export default Partners;

