import React, { useContext } from "react";
import { UploadFormContext } from "../../../store/UploadFormContext";

import "./CssModules/progress.css";

function StepProgressBar({ propertyDetailForm }) {
  const { forms, currentStage, setCurrentStage, setForms } =
    useContext(UploadFormContext);
  const numberOfStages = forms.length;

  return (
    <div className="progressbar">
      <div
        className="progress"
        id="progress"
        style={{
          width: ((currentStage - 1) / (numberOfStages - 1)) * 100 + "%",
        }}
      ></div>
      <div
        className={`progress-step ${
          currentStage >= 1 ? "progress-step-active" : ""
        }`}
        data-title="Upload Deeds"
      ></div>
      <div
        className={`progress-step ${
          currentStage >= 2 ? "progress-step-active" : ""
        }`}
        data-title="Upload EC"
      ></div>
      <div
        className={`progress-step ${
          currentStage >= 3 ? "progress-step-active" : ""
        }`}
        data-title="Upload Tax Receipt"
      ></div>
      <div
        className={`progress-step ${
          currentStage >= 4 ? "progress-step-active" : ""
        }`}
        data-title="Upload Khata"
      ></div>
      {propertyDetailForm.properytype112 &&
        propertyDetailForm.properytype112 !== "Vacant land" && (
          <>
            <div
              className={`progress-step ${
                currentStage >= 5 ? "progress-step-active" : ""
              }`}
              data-title="Upload Building Plan"
            ></div>
            <div
              className={`progress-step ${
                currentStage >= 6 ? "progress-step-active" : ""
              }`}
              data-title="Others"
            ></div>
          </>
        )}
      {propertyDetailForm.properytype112 &&
        propertyDetailForm.properytype112 === "Vacant land" && (
          <div
            className={`progress-step ${
              currentStage >= 5 ? "progress-step-active" : ""
            }`}
            data-title="Others"
          ></div>
        )}
      {/* <div
        className={`progress-step ${
          currentStage >= 5 ? "progress-step-active" : ""
        }`}
        data-title="Upload Building Plan"
      ></div>
      <div
        className={`progress-step ${
          currentStage >= 6 ? "progress-step-active" : ""
        }`}
        data-title="Others"
      ></div> */}
    </div>
  );
}

export default StepProgressBar;

// progress-step-active
