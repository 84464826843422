import React, { useEffect } from "react";
import "./CssModules/whatwedonew.css";
import greendot from "../../assets/greendot.svg";
import { BiLink } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { MdApproval } from "react-icons/md";
import { BsLightning } from "react-icons/bs";
import { MdPendingActions } from "react-icons/md";
import { GiFamilyTree } from "react-icons/gi";
import { GrDocumentMissing } from "react-icons/gr";
import { FaBalanceScale } from "react-icons/fa";
import AOS from "aos";
const WhatWeDoNew = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div id="whatwedo" className="lkgapbetweencontainer-whatwedo">
      <div className="left-dot-whatwedo">
        <img src={greendot} loading="lazy" />
      </div>
      <div
        className="whatwedon-title-group"
        data-aos="fade-up"
        data-aos-offset="50"
      >
        <h1 className="dtitle">What we do</h1>
        <p className="wsub">Title search made simple.</p>
      </div>
      <div className="right-dot-whatwedo">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="container cn-st pb-4">
        <div
          className="row "
          style={{
            width: "98.5%",
            margin: "0 auto",
          }}
        >
          {/* <div className="col-md-1"></div> */}
          <div className="col-lg-4 col-md-12 col-sm-12 pt-4 pb-4">
            <ul className="ulwit">
              <li
                className="listwiticontext1 firstrempadding"
                data-aos="fade-right"
                data-aos-offset="50"
              >
                <div className="whaticon">
                  <BiLink id="wahtwedo-greencoloricon" />
                </div>
                <div className="text-align-left">
                  <span className="ntwi">Verify </span>
                  <span className="btwi">
                    chain of <br />
                    title
                  </span>
                </div>
              </li>
              <li
                className="listwiticontext1"
                data-aos="fade-right"
                data-aos-offset="50"
              >
                <div className="whaticon">
                  <AiOutlineHome id="wahtwedo-greencoloricon" />
                </div>
                <div className="text-align-left">
                  <span className="ntwi">Check for </span>

                  <span className="btwi">
                    liens and <br />
                    encumbrances
                  </span>
                </div>
              </li>
              <li
                className="listwiticontext1"
                data-aos="fade-right"
                data-aos-offset="50"
              >
                <div className="whaticon">
                  <MdApproval id="wahtwedo-greencoloricon" />
                </div>
                <div className="text-align-left">
                  <span className="ntwi">Check for </span>
                  <span className="btwi">
                    building <br />
                    plan & property tax
                  </span>
                </div>
              </li>
              <li
                className="listwiticontext1 lastpaddingformobile"
                data-aos="fade-right"
                data-aos-offset="50"
              >
                <div className="whaticon">
                  <BsLightning id="wahtwedo-greencoloricon" />
                </div>
                <div className="text-align-left">
                  <span className="btwi">Instant </span>
                  <span className="ntwi">report </span>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="col-lg-4 col-md-12 col-sm-12 d-flex align-items-center"
            data-aos="zoom-in"
            data-aos-offset="50"
          >
            <div className="newwhatwedo-main-content">
              <p className="p-text-content">
                Title search is a crucial due diligence before any transaction
                is associated with a property.
              </p>
              <p className="p-text-content">
                As KYC is synonymous with the identity of an individual, title
                search is synonymous with the identity of a property owner.
              </p>
              <p className="p-text-content">
                But the traditional approach has proven to be time-consuming,
                expensive, and prone to human error.
              </p>
              <p
                className="p-text-content"
                style={{
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                At Lending Katalyst, we simplify the pain points of property
                title search.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 pt-4 pb-4">
            <ul className="ulwit right-ulwit">
              <li
                className="listwiticontext secondrempadding"
                data-aos="fade-left"
                data-aos-offset="50"
              >
                {/* style={{
                  marginTop: "-10px",
                }} */}
                <div className="text-right">
                  <span className="ntwi">Check for </span>

                  <span className="btwi">
                    pending
                    <br /> disputes
                  </span>
                </div>
                <div className="whaticon-right">
                  <MdPendingActions id="wahtwedo-greencoloricon" />
                </div>
              </li>
              <li
                className="listwiticontext"
                data-aos="fade-left"
                data-aos-offset="50"
              >
                <div className="text-right">
                  <span className="ntwi">Check for </span>

                  <span className="btwi">
                    family <br />
                    relations
                  </span>
                </div>
                <div className="whaticon-right">
                  <GiFamilyTree id="wahtwedo-greencoloricon" />
                </div>
              </li>
              <li
                className="listwiticontext"
                data-aos="fade-left"
                data-aos-offset="50"
              >
                <div className="text-right">
                  <span className="ntwi">Check for </span>

                  <span className="btwi">
                    missing <br />
                    documents
                  </span>
                </div>
                <div className="whaticon-right">
                  <GrDocumentMissing id="wahtwedo-greencoloricon" />
                </div>
              </li>
              <li
                className="listwiticontext lastpadding"
                data-aos="fade-left"
                data-aos-offset="50"
              >
                <div className="text-right">
                  <span className="btwi">Unbiased </span>
                  <span className="ntwi">
                    report, not <br />
                    subjective{" "}
                  </span>
                </div>
                <div className="whaticon-right">
                  <FaBalanceScale id="wahtwedo-greencoloricon" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoNew;
