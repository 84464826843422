import React, { Component } from "react";
import Header from "./header";
import Contact from "./contact";
import JsonData from "../../data/data.json";
import Footer from "./CssModules/Footer";
import WhatWeDoNew from "./WhatWeDoNew";
import AllNumbers from "./AllNumbers";
import WhyUs from "./WhyUs";
import Privacy from "./Privacy";
import HowItWorks from "../Howitworks/HowItWorks";
import CustomersSay from "./CustomersSay";
// import NewHowItWorks from "../Howitworks/NewHowItWorks";

class Home extends Component {
  state = {
    landingPageData: {},
    year: new Date().getFullYear(),
  };
  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Header data={this.state.landingPageData.Header} />
        <WhatWeDoNew />
        {/* <AllNumbers /> */}
        {/* <WhatWeDo data={this.state.landingPageData.Whatwedo} /> */}
        {/* <OurTech data={this.state.landingPageData.OurTech} /> */}
        <HowItWorks />
        <WhyUs />
        <CustomersSay />
        <Privacy />
        {/* <OurStory data={this.state.landingPageData.Ourstory} /> */}
        {/* <Team data={this.state.landingPageData.Team} /> */}
        <Contact data={this.state.landingPageData.Contact} />
        <Footer />
        {/* <div
          style={{
            width: "100%",
            height: "60px",
            backgroundColor: "lightgray",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ fontSize: "18px" }}>
            &copy; {this.state.year} Lending Katalyst. All rights reserved.
          </p>
        </div> */}
      </div>
    );
  }
}

export default Home;
