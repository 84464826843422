// import { GoogleMap, LoadScript } from "@react-google-maps/api";
import React from "react";
import { MdLocationPin } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";

import styles from "./CssModules/contact.module.css";
import { FaXTwitter } from "react-icons/fa6";

const Contact = () => {
  return (
    <section className={`${styles.container} `} id="contact">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 d-flex">
            <div
              className={styles.contactContainer}
              style={{ marginLeft: "0" }}
            >
              <h2 className={styles.contactTitle}>Contact Info</h2>
              <div className={styles.contactSubContainer}>
                <div>
                  <span>
                    <MdLocationPin />
                  </span>
                  Address
                </div>
                <p>19, Ranoji Rao Road, Basavanagudi, Bangalore - 560004</p>
              </div>
              <div className={styles.contactSubContainer}>
                <div>
                  <span>
                    <HiOutlineMail />
                  </span>
                  Email
                </div>
                <p>contactus@lendingkatalyst.com</p>
              </div>
              <div className={styles.socialNetworkingContainer}>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/LendingKatalyst"
                      target="_blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/lending-katalyst"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li className={styles.twitter_main_div}>
                    <a
                      href="https://twitter.com/LendingKatalyst"
                      target="_blank"
                    >
                      <FaXTwitter className={styles.twitter_icon} />
                      {/* <img src={x_logo}/> */}
                      {/* <i className="fa fa-twitter"></i> */}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/lendingkatalyst/"
                      target="_blank"
                    >
                      {/* <i class="fa-brands fa-instagram"></i> */}
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className={styles.privacPolicyTermsOfUse}>
                  <Link to="/privacy-policy" className={styles.whiteHoverline}>
                    Privacy Policy
                  </Link>
                  <Link to="/terms" className={styles.whiteHoverline}>
                    Terms of Use
                  </Link>
                </div> */}
            </div>
          </div>
          <div style={{ padding: "0 10px 0 10px" }} className={styles.hideit}>
            <div className={`${styles.mobilmenumb}`}></div>
          </div>

          <div
            className="col-sm-12 col-md-7 col-lg-7 d-flex"
            id={styles.seconMenuIdContact}
          >
            <div className={styles.footermenus}>
              <div className={`row `}>
                <div className={`col-md-5 col-sm-12 col-lg-5 `}>
                  <p className={styles.fottmenutitle}>Lending Katalyst</p>
                  <ul>
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact</Link>
                    </li>
                  </ul>
                  <div
                    className={`${styles.mobilmenumb} ${styles.secondThirdMenu}`}
                  ></div>
                </div>
                <div className={`col-md-4 col-sm-12 col-lg-3 `}>
                  <p className={styles.fottmenutitle}>Legal</p>
                  <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms of Use</Link>
                    </li>
                  </ul>
                  <div
                    className={`${styles.mobilmenumb} ${styles.secondThirdMenu}`}
                  ></div>
                </div>
                <div
                  className={`col-md-3 col-sm-12 col-lg-3 ${styles.lastpad}`}
                  style={{
                    border: "none",
                  }}
                >
                  <p className={styles.fottmenutitle}>Help</p>
                  <ul>
                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                  </ul>
                  {/* <div className={styles.mobilmenumb}></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
