import React, { useRef, useState, useCallback, useEffect } from "react";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import firstImage from "../../assets/1_Form.jpg";
import secondImage from "../../assets/2_Upload.jpg";
import thirdImage from "../../assets/3_Report.jpg";
import greendot from "../../assets/greendot.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./howitworks.css";
import AOS from "aos";

const HowItWorks = () => {
  const swiperRef = useRef(null);
  //   console.log(swiperRef.current.swiper.realIndex);

  const [currentSlide, setCurrentSlide] = useState(0);

  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const updateIndex = useCallback(
    () => setCurrentSlide(swiperRef.current.swiper.realIndex),
    []
  );
  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;
    // if (swiperRef) console.log(swiperRef.current.swiper.realIndex);

    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex);
      }
    };
  }, [updateIndex]);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div id="howitworks">
      <div className="left-dot-howitworks">
        <img src={greendot} loading="lazy" />
      </div>
      <div
        className="howitworks-title-group"
        data-aos="fade-up"
        data-aos-offset="50"
      >
        <h1 className="dtitle">How it works</h1>
        <p className="wsub">
          We leverage state-of-the-art machine learning algorithms to generate
          your property title report seamlessly.
        </p>
      </div>
      <div className="right-dot-howitworks">
        <img src={greendot} loading="lazy" />
      </div>
      <div className="container padding3rem">
        <div
          className="row kkldjfkldjjkgfkgk"
          data-aos="fade-up"
          data-aos-offset="50"
        >
          <div className="col-lg-4  col-sm-12 d-flex align-items-center uifkdjfkd">
            <div className="workItem">
              <h4
                className={`${
                  currentSlide == 0 ? "activeworkitemm" : ""
                } workitme-h4`}
              >
                Enter Property Details
              </h4>
            </div>
            <div className="straight-line"></div>
          </div>
          <div className="vertical-line"></div>
          <div
            className="col-lg-4  col-sm-12 d-flex align-items-center uifkdjfkd"
            style={{
              paddingRight: "0",
            }}
          >
            <div className="workItem">
              <h4
                className={`${
                  currentSlide == 1 ? "activeworkitemm" : ""
                } workitme-h4`}
              >
                Upload Property Documents
              </h4>
            </div>
            <div className="straight-line"></div>
          </div>
          <div className="vertical-line"></div>

          <div
            className="col-lg-4 col-sm-12 d-flex align-items-center uifkdjfkd"
            style={{
              paddingLeft: "0",
            }}
          >
            <div className="straight-line"></div>
            <div className="workItem">
              <h4
                className={`${
                  currentSlide == 2 ? "activeworkitemm" : ""
                } workitme-h4`}
              >
                Review, Edit and Save
              </h4>
            </div>
          </div>
        </div>

        <Swiper
          data-aos="fade-up"
          data-aos-offset="50"
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          ref={swiperRef}
        >
          <SwiperSlide>
            <div className="slider-imge-div">
              <img src={firstImage} alt="first image" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-imge-div">
              <img src={secondImage} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-imge-div">
              <img src={thirdImage} />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HowItWorks;
