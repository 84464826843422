import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { Toaster } from "react-hot-toast";

import { UploadFormContext } from "../../store/UploadFormContext";

import styles from "./formsProceed.module.css";
import "./Forms_common/CssModules/progress.css";
import StepProgressBar from "./Forms_common/StepProgressBar";
import apiHandler from "./apiHandler";
import AuthContext from "../../store/auth-context";
import LendingSpinner from "../Spinner/LendingSpinner";

const Deeds = lazy(() => import("./FormsMain/UploadDeeds"));
const EC = lazy(() => import("./FormsMain/UploadEC"));
const TaxReceipt = lazy(() => import("./FormsMain/UploadTaxReceipt"));
const Khata = lazy(() => import("./FormsMain/UploadKhata"));
const BuildingPlan = lazy(() => import("./FormsMain/UploadBuildingPlan"));
const Others = lazy(() => import("./FormsMain/Others"));

function Upload() {
  const { token } = useContext(AuthContext);
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [propertyDetailForm, setPropertyDetailForm] = useState({});

  const {
    forms,
    currentStage,
    setCurrentStage,
    deeds,
    ec,
    taxReceipt,
    khata,
    buildingPlan,
    others,
    uploadFormData,
    setForms,
    copyOfForm
  } = useContext(UploadFormContext);

  const history = useHistory();
  let location = useLocation();
  const [navHeight, setNavHeight] = useState(80);

  useEffect(() => {
    authCtx.checkLoginStatus();
    setPropertyDetailForm(location.data);

    if (location && !location.data && location.data !== "check") {
      history.push("/forms");
    }
    if (!authCtx.getToken()) {
      history.push("/");
    }
    // if (!location.state?.from) {
    // }
    let navBar = document.querySelector("nav");
    if (navBar) {
      setNavHeight(navBar.offsetHeight);
    }
    window.scrollTo({ top: 0 });
    if (
      propertyDetailForm.properytype112 &&
      propertyDetailForm.properytype112 === "Vacant land"
    ){
      let newData = [...copyOfForm];
      setForms(newData.filter((data) => data.formType != "Building Plan"));
    }else{
      setForms(copyOfForm)
    }
  }, []);
  const numberOfStages = forms.length;


  const nextStage = () => {
    setCurrentStage((prevState) =>
      prevState >= numberOfStages - 1 ? numberOfStages : prevState + 1
    );
  };

  const prevStage = () => {
    setCurrentStage((prevState) => (prevState <= 1 ? 1 : prevState - 1));
  };

  const loadValues = async () => {
    const deedNos = [];
    await deeds.forEach((deed) => {
      deedNos.push(deed.deedNumber);
      uploadFormData.append("deedFiles", deed.file);
    });
    const othersFormDetails = [];
    await others.forEach((item) => {
      othersFormDetails.push({
        formType: item.formType,
        formDetails: item.formDetails,
      });
      uploadFormData.append("othersFiles", item.file);
    });
    const { file, ...buildingPlanDetails } = buildingPlan;
    uploadFormData.append("deedNos", JSON.stringify(deedNos));
    uploadFormData.append("ecFormType", ec.formType);
    uploadFormData.append("ecFile", ec.file);
    uploadFormData.append("taxReceiptNumber", taxReceipt.receiptNumber);
    uploadFormData.append("taxReceiptFile", taxReceipt.file);
    uploadFormData.append("khataFormType", khata.formType);
    uploadFormData.append("khataDetails", JSON.stringify(khata.khataDetails));
    uploadFormData.append("khataFile", khata.file);

    uploadFormData.append(
      "buildingPlanDetails",
      JSON.stringify(buildingPlanDetails)
    );
    uploadFormData.append("buildingPlanFile", file);

    uploadFormData.append(
      "othersFormDetails",
      JSON.stringify(othersFormDetails)
    );
    uploadFormData.append("state", propertyDetailForm.state);
    uploadFormData.append("district", propertyDetailForm.district);
    uploadFormData.append("village", propertyDetailForm.village);
    uploadFormData.append("fullName", propertyDetailForm.fullName);
    uploadFormData.append("address", propertyDetailForm.address);
    uploadFormData.append(
      "propertyIdType1",
      propertyDetailForm.propertyIdType1
    );
    uploadFormData.append("propertyId1", propertyDetailForm.propertyId1);
    uploadFormData.append(
      "propertyIdType2",
      propertyDetailForm.propertyIdType2
    );
    uploadFormData.append("propertyId2", propertyDetailForm.propertyId2);
    uploadFormData.append("propertyType", propertyDetailForm.properytype112);
    uploadFormData.append("serviceType", "title report");
  };
  // height: `calc(100% - ${navHeight + "px"})`,
  return (
    <div
      className={styles.onlyformargin}
      style={{
        marginTop: `${navHeight + "px"}`,
        width: "100%",
      }}
    >
      {loading && <LendingSpinner></LendingSpinner>}

      <div className={styles.containerParent}>
        <div className="container" style={{ marginTop: "5rem" }}>
          <Card className={styles.uploadCard}>
            <Card.Body className={styles.uploadCardBody}>
              <div>
                <h2
                  className={styles.uploadDocTitle}
                  style={{
                    textTransform: "none",
                    fontSize: "25px",
                    fontFamily: "open sans",
                  }}
                >
                  Upload Documents
                </h2>
                <StepProgressBar propertyDetailForm={propertyDetailForm} />
                <Suspense fallback={<div>Loading</div>}>
                  <div>
                    <div
                      className={`form-step
                     ${currentStage === 1 ? "form-step-active" : ""}
                      `}
                    >
                      {currentStage === 1 && <Deeds />}
                    </div>
                    <div
                      className={`form-step ${
                        currentStage === 2 ? "form-step-active" : ""
                      }`}
                    >
                      {currentStage === 2 && <EC />}
                    </div>
                    <div
                      className={`form-step ${
                        currentStage === 3 ? "form-step-active" : ""
                      }`}
                    >
                      {currentStage === 3 && <TaxReceipt />}
                    </div>
                    <div
                      className={`form-step ${
                        currentStage === 4 ? "form-step-active" : ""
                      }`}
                    >
                      {currentStage === 4 && <Khata />}
                    </div>

                    {propertyDetailForm.properytype112 &&
                      propertyDetailForm.properytype112 === "Vacant land" && (
                        <div
                          className={`form-step ${
                            currentStage === 5 ? "form-step-active" : ""
                          }`}
                        >
                          {currentStage === 5 && (
                            <Others propertyDetailForm={propertyDetailForm} />
                          )}
                        </div>
                      )}
                    {propertyDetailForm.properytype112 &&
                      propertyDetailForm.properytype112 !== "Vacant land" && (
                        <>
                          <div
                            className={`form-step ${
                              currentStage === 5 ? "form-step-active" : ""
                            }`}
                          >
                            {currentStage === 5 && <BuildingPlan />}
                          </div>
                          <div
                            className={`form-step ${
                              currentStage === 6 ? "form-step-active" : ""
                            }`}
                          >
                            {currentStage === 6 && (
                              <Others propertyDetailForm={propertyDetailForm} />
                            )}
                          </div>
                        </>
                      )}
                    {/* <div
                      className={`form-step ${
                        currentStage === 5 ? "form-step-active" : ""
                      }`}
                    >
                      {currentStage === 5 && <BuildingPlan />}
                    </div>
                    <div
                      className={`form-step ${
                        currentStage === 6 ? "form-step-active" : ""
                      }`}
                    >
                      {currentStage === 6 && (
                        <Others propertyDetailForm={propertyDetailForm} />
                      )}
                    </div> */}
                  </div>
                </Suspense>
              </div>
              <div className={styles.buttonContainer}>
                {currentStage == 1 && (
                  <button
                    className={` ${styles.prevbtn}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: "/forms",
                        state: {
                          from: location.pathname,
                        },
                        data: propertyDetailForm,
                      });
                    }}
                  >
                    Back
                  </button>
                )}
                {currentStage !== 1 && (
                  <button
                    className={` ${styles.prevbtn}`}
                    onClick={(e) => {
                      e.preventDefault();
                      prevStage();
                    }}
                  >
                    Previous
                  </button>
                )}
                {currentStage !== numberOfStages && (
                  <button
                    className={`${styles.nextButton} ${
                      styles.formproceedgreenbutton
                    }
                  ${
                    !forms.find((form, index) => index === currentStage - 1)
                      .validation
                      ? styles.disableButton
                      : styles.proceedhover
                  }
                  `}
                    disabled={
                      !forms.find((form, index) => index === currentStage - 1)
                        .validation
                    }
                    onClick={async (e) => {
                      e.preventDefault();
                      nextStage();
                    }}
                    title={
                      !forms.find((form, index) => index === currentStage - 1)
                        .validation
                        ? "Please fill all the mandatory fields and appropriately"
                        : "Go to the next stage"
                    }
                  >
                    Next
                  </button>
                )}
                {currentStage === numberOfStages && (
                  <button
                    className={`${styles.submitButton} ${
                      styles.formproceedgreenbutton
                    } 
                    ${
                      !forms[forms.length - 1].validation
                        ? styles.disableButton
                        : styles.proceedhover
                    }`}
                    disabled={!forms[forms.length - 1].validation}
                    onClick={async (e) => {
                      e.preventDefault();
                      console.log({
                        deeds,
                        ec,
                        taxReceipt,
                        khata,
                        buildingPlan,
                        others,
                      });
                      await loadValues();
                      apiHandler(
                        uploadFormData,
                        authCtx.getToken(),
                        setLoading,
                        authCtx,
                        history
                      );
                    }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Upload;
