import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../vakilserach_common.css";
import classes from "./MainNavigation.module.css";
import "./vakilsearch_navbar.css";
// import lkpoweredlibra from "../../../assets/lk_libra.svg";
// import lkpoweredlibra from "../../../assets/final-libra.jpg";
import { AiFillHome } from "react-icons/ai";
const ValikSearchMainNavigation = () => {
  // const authCtx = useContext(AuthContext);
  const history = useHistory();
  // const [checkBoxChecked, setCheckboxChecked] = useState(false);
  const [checkIfInDashboard, setCheckIfInDashboard] = useState(false);
  const location = useLocation();
  // console.log("🚀 ~ ValikSearchMainNavigation ~ location:", location);
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  // const nonce = query.get("nonce");
  // const enterpriseId = query.get("enterpriseid");

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const navLinks = document.querySelectorAll("nav ul li a");
    // let url = window.location.href;
    // let sectionId = url.split("#")[1];
    let mainElement = document.getElementsByTagName("main");
    if (mainElement) {
      mainElement[0].style.background = "#F7F7F7";
    }

    if (
      window.location.pathname == "/vakil/dashboard" ||
      window.location.pathname == "/vakil/report-status"
    ) {
      setCheckIfInDashboard(true);
    } else {
      setCheckIfInDashboard(false);
    }
    if (window.location.pathname == "/") {
      let nav = document.querySelector("nav");
      nav.style.position = "fixed";
    } else {
      let nav = document.querySelector("nav");
      nav.style.position = "fixed";
      nav.style.top = 0;
    }

    let prevScrollpos = 50;
    const handleScroll = () => {
      if (window.location.pathname == "/") {
        let currentScrollPos = window.scrollY;
        let nav = document.querySelector("nav");

        if (prevScrollpos > currentScrollPos) {
          nav.style.top = "0";
          if (currentScrollPos === 0) {
            if (nav.classList.contains("boxShadow")) {
              nav.classList.remove("boxShadow");
              // nav.style.top = "0";
            }
          } else {
            if (!nav.classList.contains("boxShadow"))
              nav.classList.add("boxShadow");
          }
          // document.querySelector("nav").style.top = "0";
        } else {
          if (currentScrollPos != 0 && currentScrollPos > 50) {
            nav.style.top = "-100px";
            if (nav.classList.contains("boxShadow"))
              nav.classList.remove("boxShadow");
          }
        }
        prevScrollpos = currentScrollPos;
      }
      let current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeigh = section.clientHeight;
        if (window.scrollY >= sectionTop - sectionHeigh / 2) {
          current = section.getAttribute("id");
        }
      });

      navLinks.forEach((li) => {
        li.classList.remove("activeNavLink");

        if (li.classList.contains(current)) {
          li.classList.add("activeNavLink");
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    // return () => window.removeEventListener("scroll", handleScroll);
  }, [window.location.href]);

  const checkPartionReviewPage = () => {
    return (
      window.location.pathname.includes("report-status")
    );
  };

  return (
    <nav
      className={`${classes.navbar} ${
        checkPartionReviewPage() ? "boxShadow1" : ""
      }`}
      style={{ padding: "20px 32px" }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div className={classes.logoDiv}>
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: `/vakil/dashboard`,
                search: `?token=${token}`,
              })
            }
          >
            <AiFillHome
              style={{
                color: "1F2F5D",
                height: "20px",
                width: "20px",
              }}
            />
            <span className="vakil_main_menu_with_buttonn">Main Menu</span>
          </div>
        </div>
        {/* <div
          style={{
            cursor: "pointer",
          }}
          className={classes.logoDivAnchor}
        >
          <div
            className={classes.logoContainer}
            style={{ width: "300px", height: "60px" }}
          >
            <img
              alt="LK"
              src={lkpoweredlibra}
              className={classes.logo}
              loading="eager"
              style={{
                width: "100%",
                objectFit: "contain",
                height: "100%",
              }}
            />
          </div>
        </div> */}

        <div>
          <span
            onClick={() => {
              window.open("/pdf/tutorial_ppt.pdf");
            }}
            className="vakil_main_menu_with_buttonn"
            style={{
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "normal",
            }}
          >
            View Tutorial
          </span>
        </div>
      </div>

      {/* <div className={classes.navActions}>
        {!checkIfInDashboard && (
          <div
            className={`${classes.signUp} ${classes.button}`}
            onClick={() =>
              history.push({
                pathname: `/vakil/dashboard`,
                search: `?token=${token}`,
              })
            }
          >
            Dashboard
          </div>
        )}
      </div> */}
    </nav>
  );
};

export default ValikSearchMainNavigation;
