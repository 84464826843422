import React from "react";
import "./librafooter.css";
import libra_footer from "../../../assets/logo2_transparent.png";
const LibraFooter = () => {
  return (
    <footer id="librafooter">
      <span className="powered_by">Powered by</span>
      <div className="d-flex align-items-center">

      <img
        alt="LK"
        src={libra_footer}
        className='logo'
        loading="eager"
      />

      <div className="libra_footer_title" style={{ whiteSpace: "nowrap" }}>
        Lending <span style={{ fontWeight: 650 }}>Katalyst</span>
      </div>
      </div>
    </footer>
  );
};

export default LibraFooter;
