import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import { useHistory } from "react-router-dom";

import "./comingsoon.css";

const ComingSoon = () => {
  const [navHeight, setNavHeight] = useState(80);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    authCtx.checkLoginStatus();
    if (!authCtx.getToken()) {
      history.push("/");
    }
    let navBar = document.querySelector("nav");
    if (navBar) {
      setNavHeight(navBar.offsetHeight);
    }
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <div style={{ paddingTop: `${navHeight + 5}px` }}>
        <div
          className="form-back-image"
          style={{
            height: `calc(100% - ${navHeight + 5}px)`,
          }}
        >
          <div className="whiteback__drag_coming_soon">
            <h1>Coming soon......</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
