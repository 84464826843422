import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Spinner.css";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";

const LendingSpinner = ({ id, shouldShowProgress }) => {
  const fileRef = useRef(0);
  const [{ totalUploaded, currentLoaded, fixPercentage }, setProgresses] =
    useState({
      totalUploaded: 0,
      currentLoaded: 0,
      fixPercentage: 0,
    });
  // const [uploadedSize, setUploadedSize] = useState(0);
  // const [progress, setProgress] = useState(0);
  const onUploadProgress = useCallback((detail) => {
    const { files, progress: currentProgress, isFixed, percentage } = detail;
    // console.log(detail, "isFixed:true, percentage:5");?
    if (!fileRef.current) {
      fileRef.current = files.reduce((p, c) => p + c.size, 0);
    }
    if (currentProgress && currentProgress.total && currentProgress.loaded) {
      // const totalSize = uploadedSize + currentProgress.loaded;
      // console.log(
      //   uploadedSize,
      //   progress,
      //   "------",
      //   totalSize,
      //   "------------------",
      //   fileRef.current
      // );
      // if (currentProgress.total === currentProgress.loaded) {
      //   setUploadedSize((prev) => prev + currentProgress.total);
      // }
      // setProgress(Math.floor((totalSize * 90) / fileRef.current));
      setProgresses((prev) => ({
        totalUploaded:
          currentProgress.total === currentProgress.loaded
            ? prev.totalUploaded + currentProgress.total
            : prev.totalUploaded,
        currentLoaded: prev.totalUploaded + currentProgress.loaded,
        fixPercentage: prev.fixPercentage,
      }));
    }
    if (isFixed) {
      // console.log(isFixed, percentage, ":IsFixed------>")
      // setProgress(progress + percentage);
      setProgresses((prev) => ({
        ...prev,
        fixPercentage: prev.fixPercentage + percentage,
      }));
    }
  }, []);

  useEffect(() => {
    document.addEventListener("httpUploadProgress", (e) => {
      if (e?.detail) onUploadProgress(e.detail);
      // console.log("Listened");
    });
    return () => {
      document.removeEventListener("httpUploadProgress", () =>
        onUploadProgress({})
      );
    };
  }, []);

  const progressPercentage = useMemo(() => {
    // console.log(currentLoaded, fileRef.current, fixPercentage);
    if (fileRef.current === 0) return 0;
    let per = Math.floor(Math.floor((currentLoaded * 90) / fileRef.current));
    if (fixPercentage > 0) {
      per = per + fixPercentage;
    }
    // console.log(per);
    return Math.min(per, 100);
  }, [totalUploaded, currentLoaded, fixPercentage]);

  return (
    <div className="spinner-overlay">
      <div className="spinner-div">
        <Spinner
          animation="border"
          variant="success"
          id={id ? id : null}
          style={{
            height: "100px",
            width: "100px",
            color: `rgb(248, 201, 75)!important`,
          }}
        />
      </div>
      {shouldShowProgress ? (
        <div className="progress-bar-div">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              marginTop: 15,
            }}
          >
            <div style={{ flex: 1, textAlign: "end" }}>
              <span className="text">Uploading</span>
            </div>
            <div style={{ width: 150 }}>
              <ProgressBar
                now={progressPercentage}
                variant={id ? "warning" : "success"}
                style={{
                  width: "150px",
                  backgroundColor: "white",
                  height: 10,
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <span className="text">{progressPercentage}%</span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LendingSpinner;