import React, { createContext, useState } from "react";

export const UploadFormContext = createContext();

function UploadFormContextProvider({ children }) {
  const [propertyDetailForm, setPropertyDetailForm] = useState({});
  const copyOfForm=[
    { formType: "Deeds", stage: 1, validation: false },
    { formType: "EC", stage: 2, validation: false },
    { formType: "Tax Receipt", stage: 3, validation: false },
    { formType: "Khata", stage: 4, validation: false },
    { formType: "Building Plan", stage: 5, validation: false },
    { formType: "Others", stage: 6, validation: false },
  ]
  const [forms, setForms] = useState([
    { formType: "Deeds", stage: 1, validation: false },
    { formType: "EC", stage: 2, validation: false },
    { formType: "Tax Receipt", stage: 3, validation: false },
    { formType: "Khata", stage: 4, validation: false },
    { formType: "Building Plan", stage: 5, validation: false },
    { formType: "Others", stage: 6, validation: false },
  ]);

  const [currentStage, setCurrentStage] = useState(1);

  const [deeds, setDeeds] = useState([{ deedNumber: null, file: null }]);
  const [ec, setEC] = useState({ formType: null, file: null });

  const [taxReceipt, setTaxReceipt] = useState({
    receiptNumber: null,
    file: null,
  });
  const [khata, setKhata] = useState(null);
  const [buildingPlan, setBuildingPlan] = useState({
    propertyIDType: "",
    propertyID: "",
    issuer: "",
    orderNumber: "",
    issueDate: "",
    file: null,
  });
  const [others, setOthers] = useState([{ formType: "", file: null }]);

  const formValues = {
    deeds,
    setDeeds,
    ec,
    setEC,
    taxReceipt,
    setTaxReceipt,
    khata,
    setKhata,
    buildingPlan,
    setBuildingPlan,
    others,
    setOthers,
  };

  const [submitValues, setSubmitValues] = useState({});

  const uploadFormData = new FormData();
  let formDetails={};
  return (
    <UploadFormContext.Provider
      value={{
        forms,
        setForms,
        currentStage,
        setCurrentStage,
        ...formValues,
        submitValues,
        setSubmitValues,
        propertyDetailForm,
        setPropertyDetailForm,
        uploadFormData,
        copyOfForm,
        formDetails
      }}
    >
      {children}
    </UploadFormContext.Provider>
  );
}

export default UploadFormContextProvider;
